export const getLastFourMonths = () => {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth(); // 0-11
    const lastFourMonths = [];

    // If the current month is January (index 0), the last four months will be December, November, October, and September
    for (let i = 1; i < 4; i++) {
        const monthIndex = (currentMonthIndex - i + 12) % 12; // Wrap around using modulo
        lastFourMonths.unshift(months[monthIndex]); // Add to the beginning of the array
    }

    return lastFourMonths;
};

import { CircleX } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import { API_URL } from "../../config";
import axios from "axios";
import { toast } from "sonner";

const initialFormData = {
    name: '', phone_number: '', effective_date: new Date().toISOString().split("T")[0], valid_date: '', state: '',
    // Step 2
    vehicle_number: '', vehicle_type: '', vehicle_mode: 'adhoc', vehicle_rate: '',
}

const AddAdhocDetails = ({ isOpen, close, hubId, onSubmit }) => {
    // console.log("isOpen", isOpen, close);
    const BASE_URL = API_URL;
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);
    const [States, setStates] = useState([]);
    const [, setIsVendorId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const today = new Date();
    const currentDate = today.toISOString().split('T')[0];

    // ALL STATE OF HUB
    const fetchStates = useCallback(async () => {
        try {
            const response = await axios.get(`${BASE_URL}/hubs/unique?query=state`, {
                headers: {
                    "ngrok-skip-browser-warning": "true",
                },
            });
            console.log("state", response.data.data);
            
            if (Array.isArray(response.data.data)) {
                setStates(response.data.data); // Ensure setStates is available in scope
            } else {
                console.error("Expected an array, got:", response.data);
            }
        } catch (error) {
            console.error("Error fetching states:", error);
        }
    }, [BASE_URL]);

    // Reset step to 1 when the modal opens
    useEffect(() => {
        if (isOpen) {
            fetchStates();
        }
    }, [isOpen, fetchStates]);

    const handleInputChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const handleNext = async () => {
        setIsLoading(true);
        setErrorMessage(''); 

        if (step === 1) {
            const { name, phone_number, effective_date, valid_date, state } = formData;
            
            if (!name || !phone_number || !effective_date || !valid_date || !state) {
                setErrorMessage('All fields in Step 1 are required.');
                setIsLoading(false);
                return;
            }
    
            // Check if valid date is greater than or equal to effective date
            const effectiveDate = new Date(effective_date);
            const validDate = new Date(valid_date);
            if (validDate < effectiveDate) {
                setErrorMessage('Valid date must be greater than or equal to the effective date.');
                setIsLoading(false);
                return;
            }
        }
        
        // Validation for Step 2
        if (step === 2) {
            const { vehicle_type, vehicle_rate } = formData;
            
            if (!vehicle_type || !vehicle_rate) {
                setErrorMessage('Vehicle Type, and Vehicle Rate are required.');
                setIsLoading(false);
                return;
            }
    
            // Validate that vehicle rate is greater than 0
            const vehicleRate = parseFloat(vehicle_rate);
            if (isNaN(vehicleRate) || vehicleRate <= 0) {
                setErrorMessage('Vehicle Rate should be a positive number.');
                setIsLoading(false);
                return;
            }
        }
    
        try {
            let stepSuccess = false; // Flag to track if the step was successful
            if (step === 1) {
                await handleStepOneSubmit();
                stepSuccess = true; // Set to true if submission is successful
            } 
            if (step === 2) {
                await handleStepSecondSubmit();
                stepSuccess = true; // Set to true if submission is successful
            }
            if (stepSuccess) {
                setStep(prevStep => prevStep + 1);
            }
        } catch (error) {
            console.error('Error while processing:', error);
            // toast.error('An error occurred. Please try again.', {
            //     duration: 3000,
            // });
        } finally {
            setIsLoading(false);
        }
    };
    

    const handleBack = () => {
        setStep((prev) => prev - 1);
    };

    const handleStepOneSubmit = async () => {
        try {
            const payload = {
                name: formData.name || 'N/A',
                phone_number: formData.phone_number || 'N/A',
                state: formData.state || 'N/A',
                effective_date: formData.effective_date || 'N/A',
                hub_ids: hubId ? [hubId] : [],
                valid_date: formData.valid_date || 'N/A',
                type:"adhoc"
            };
            console.log("payload-1", payload) 
            const response = await axios.post(`${BASE_URL}/vendor`, 
            payload,
            {
                headers: {
                    "ngrok-skip-browser-warning": "true",
                },
            });
            console.log("step-1", step, response.data.data);
            setIsVendorId(response?.data?.data?.id)
            onSubmit(response.data.data);
            return {success:true, data : response?.data?.id}
        } catch (err) {
            console.log("error", err)
        }
    };

    const handleStepSecondSubmit = async (id) => {
        if(!id){
            toast.error(`Failed to create vendor!`, {
              duration: 3000,
            });
            return {success: false, message: "Vendor ID is missing!"}
          }
        try {
            // Map the filtered vehicle details to extract all vehicle-related data
            const payload = {
                hub_id: hubId || 'N/A',
                vehicle_number: formData.vehicle_number ? [formData.vehicle_number] : [],
                type: formData.vehicle_type || '',
                vehicle_mode: formData.vehicle_mode || '',
                vehicle_rate: parseFloat(formData.vehicle_rate) || '',
                vendor_id: id,
            };

            console.log("payload-2", payload);
            const response = await axios.post(`${BASE_URL}/vendor/vehicle`, {
                vehicleDetails: [payload]
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": "true",
                },
            });
            console.log("step-2", step, response.data);
            if(response.status === 200 || response.status === 201){
                close();
                console.log("inside")
                toast.success(`Adhoc vendor details submitted successfully!`, {
                    duration: 3000,
                  });
                //   onSubmit(response.data.data[0]);
            }

        } catch (err) {
            console.log("error", err.response.data.message);
            // toast(err)
        }
    };

    // const handleFormSubmit = async() => {
    //     try {
    //         // Call the first step submission function
    //         let {success, data} = await handleStepOneSubmit();
    //         if(!success){
    //           return;
    //         }
            
    //         await handleStepSecondSubmit(data);
      
    //       } catch (error) {
    //         console.error("Error during form submission:", error);
    //         toast.error("An error occurred during submission. Please try again.", {
    //           duration: 3000,
    //         });
    //       }
    // }


    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className="grid grid-cols-1 gap-4">
                        <label className="block text-white">
                            Adhoc Vendor Name
                            <input
                                type="text"
                                placeholder="Enter Adhoc Vendor Name"
                                value={formData?.name || ''}
                                onChange={(e) => handleInputChange('name', e.target.value)}
                                className="block w-full bg-[#1F1F1F] border border-gray-600 rounded-md shadow-sm p-2 text-white"
                            />
                        </label>
    
                        <label className="block text-white">
                            Adhoc Vendor Contact
                            <input
                                type="number"
                                placeholder="Enter Adhoc Vendor Contact"
                                value={formData?.phone_number || ''}
                                onChange={(e) => handleInputChange('phone_number', e.target.value)}
                                className="block w-full bg-[#1F1F1F] border border-gray-600 rounded-md shadow-sm p-2 text-white"
                            />
                        </label>
    
                        <label className="block text-white">
                            State
                            <select
                                value={formData?.state || ''}
                                onChange={(e) => handleInputChange('state', e.target.value)}
                                className="block w-full bg-[#1F1F1F] border border-gray-600 rounded-md shadow-sm p-2 text-white"
                            >
                                <option value="" disabled>
                                    Select the State
                                </option>
                                {Array.isArray(States) && States.length > 0 ? (
                                    States.map((item) => (
                                        <option key={item} value={item}>
                                            {item}
                                        </option>
                                    ))
                                ) : (
                                    <option value="" disabled>
                                        No states available
                                    </option>
                                )}
                            </select>
                        </label>
    
                        <label className="block text-white">
                            Effective Date
                            <input
                                type="date"
                                placeholder="Enter Effective Date"
                                value={formData?.effective_date || ''}
                                readOnly
                                className="block w-full bg-[#1F1F1F] border border-gray-600 rounded-md shadow-sm p-2 text-white"
                            />
                        </label>
    
                        <label className="block text-white">
                            Valid Date
                            <input
                                type="date"
                                placeholder="Enter Valid Date"
                                value={formData?.valid_date || ''}
                                onChange={(e) => handleInputChange('valid_date', e.target.value)}
                                min={currentDate}
                                className="block w-full bg-[#1F1F1F] border border-gray-600 rounded-md shadow-sm p-2 text-white"
                            />
                        </label>
    
                        {errorMessage && (
                            <p className="text-red-500">{errorMessage}</p>
                        )}
                    </div>
                );
    
            case 2:
                return (
                    <div className="grid grid-cols-1 gap-4">
                        <label className="block text-white">
                            Vehicle Number
                            <input
                                type="text"
                                placeholder="Enter Vehicle Number"
                                value={formData?.vehicle_number || ''}
                                onChange={(e) => handleInputChange('vehicle_number', e.target.value)}
                                className="block w-full bg-[#1F1F1F] border border-gray-600 rounded-md shadow-sm p-2 text-white"
                            />
                        </label>
    
                        <label className="block text-white">
                            Vehicle Type
                            <input
                                type="text"
                                placeholder="Enter Vehicle Type"
                                value={formData?.vehicle_type || ''}
                                onChange={(e) => handleInputChange('vehicle_type', e.target.value)}
                                className="block w-full bg-[#1F1F1F] border border-gray-600 rounded-md shadow-sm p-2 text-white"
                            />
                        </label>
    
                        <label className="block text-white">
                            Vehicle Mode
                            <input
                                type="text"
                                placeholder="Enter Vehicle Mode"
                                value={formData?.vehicle_mode || 'adhoc'}
                                className="block w-full bg-[#1F1F1F] border border-gray-600 rounded-md shadow-sm p-2 text-white"
                                disabled
                            />
                        </label>
    
                        <label className="block text-white">
                            Vehicle Rate Per Day
                            <input
                                type="number"
                                placeholder="Enter Vehicle Rate Per Day"
                                value={formData?.vehicle_rate || ''}
                                onChange={(e) => handleInputChange('vehicle_rate', e.target.value)}
                                className="block w-full bg-[#1F1F1F] border border-gray-600 rounded-md shadow-sm p-2 text-white"
                            />
                        </label>
    
                        {errorMessage && (
                            <p className="text-red-500">{errorMessage}</p>
                        )}
                    </div>
                );
    
            default:
                return null;
        }
    };
    

    return isOpen ? (
        <div
            className="fixed inset-0 z-50 flex justify-center items-center w-full h-screen bg-gray-900 bg-opacity-50 overflow-x-hidden overflow-y-auto"
        >
            <div
                className="relative p-4 w-full max-w-3xl max-h-screen"
                style={{
                    height: '80vh', // Increase height
                    width: '30vw',  // Increase width
                }}
            >
                <div className="relative bg-black rounded-lg shadow-lg p-6 h-full"
                >
                    <div className='absolute top-3 right-3 cursor-pointer' onClick={close}>
                        <CircleX size={15} />
                    </div>
                    <div className="flex justify-between items-center mb-4 mt-2">
                        <h2 className="text-lg font-semibold text-white">Create Vendor</h2>
                        <span className="text-white">Step {step} of 2</span>
                    </div>
                    <div className='flex flex-col items-end'>
                        {step > 1 && (
                            <button onClick={handleBack} className="mb-2 text-white">
                                ← Back
                            </button>
                        )}
                    </div>
                    <div
                        style={{
                            marginBottom: '20px',
                        }}
                    >
                        {renderStep()}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {/* {step < 2 ? ( */}
                            <button
                                className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors mt-4"
                                onClick={handleNext} disabled={isLoading}>
                                {step === 2 ?  'Submit' : 'Next'}
                            </button>
                        {/* ) : (
                            <button
                                className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors mt-4"
                                onClick={handleStepSecondSubmit} disabled={isLoading}>
                                Submit
                            </button>
                        )} */}
                    </div>
                </div>
            </div>
        </div>

    ) : null;
};

export default AddAdhocDetails;


import React, { useEffect, useState } from 'react';
import { CircleX } from 'lucide-react';

const CustomSelectInput = ({ handleRightClick, isOpen, close }) => {
  const [vehicleNumber, setVehicleNumber] = useState(''); // State for the vehicle number

  const handleInputChange = (e) => {
    // const formattedValue = e.target.value.replace(/\s+/g, ''); // Remove all spaces
    setVehicleNumber(e.target.value); 
  };

  console.log("vehicleNumber from modal", vehicleNumber);

   // Construct the payload as expected for the PUT request
    const payload = {
      vehicle_number: vehicleNumber  // Flattening the array of vehicle numbers
    };
    console.log("payload", payload);

  useEffect(() => {
    if (isOpen) {
      // Optional: Reset the vehicle number when the modal opens
      setVehicleNumber('');
    }
  }, [isOpen]); // Add isOpen to the dependency array

  return (
    <div>
      {/* Main modal */}
      {isOpen && (
        <div
          className="fixed inset-0 z-50 flex justify-center items-center w-full h-screen bg-gray-900 bg-opacity-50 overflow-x-hidden overflow-y-auto"
          aria-hidden="true"
        >
          <div className="relative p-4 w-full max-w-sm max-h-full"> {/* Further reduced width */}
            {/* Modal content */}
            <div className="relative bg-black rounded-lg shadow-lg p-6">
            <div className='absolute top-3 right-3 cursor-pointer' onClick={close}>
                        <CircleX size={15}/>
                    </div>
              <h2 className="text-lg font-semibold mb-4 text-white text-left">
                Add Vehicle Number
              </h2>

              <input
                type="text"
                id="vehicle_input"
                name="vehicle_input"
                placeholder="Enter Vehicle Number"
                value={vehicleNumber} // Bind input value to state
                onChange={handleInputChange} // Call function on change
                className="block w-full bg-[#1F1F1F] border border-gray-600 rounded-md shadow-sm p-2 text-white"
              />

              <button
                onClick={() => handleRightClick(payload)} // Pass vehicleNumber as a parameter
                className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors mt-4"
              >
                ADD
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelectInput;

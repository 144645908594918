import React from "react";
import { Routes, Route } from "react-router-dom";

import Cod from "../pages/Cod";
import Expense from "../pages/Expense";
import VendorVehicle from "../pages/VendorVehicle";
import HubSetup from "../pages/HubSetup";
import Modal from "../components/Modal";

const routes = [
  {
    path: "/cod",
    exact: true,
    element: <Cod />,
    private: false,
  },
  {
    path: "/expense",
    exact: true,
    element: <Expense />,
    private: false,
  },
  {
    path: "/vendor",
    exact: true,
    element: <VendorVehicle />,
  }, {
    path: "/hubsetup",
    exact: true,
    element: <HubSetup />,
    private: false,
  },
  {
    path: "/test",
    exact: true,
    element: <Modal modalButtonTitle={"Open"} modalHeading={"modal"} />,
    private: false,
  }
];

export default function Navigation() {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}
